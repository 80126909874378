import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Typography, Card, Table, TableHead, TableRow, TableCell, TableBody, TextField, Alert, Grid, CardContent
} from '@material-ui/core';
import {Link as RouterLink, Link} from 'react-router-dom';
import {useGet, useDelete, usePost, usePut} from '../../../API/request'
import PerfectScrollbar from "react-perfect-scrollbar";
import {useConfirm} from '../../../components/confirm';
import {BallTriangle} from "react-loader-spinner";
import '../../../styles/Emails/email.css'
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const EmailSettings = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    const getU = useGet();
    const postU = usePost();
    const putU = usePut();
    const deleteU = useDelete();
    const confirm = useConfirm();

    const [values, setValues] = useState({
        email: '',
        password: '',
        message: '',
    });

    const [errors, setErrors] = useState({
        email: false,
        password: false,
        message: false
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const hideValue = (event) => {
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text, hideAlert = true) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        if (hideAlert) {
            setTimeout(() => {
                setAlert({
                    ...alert,
                    isVisible: false
                });

                setSubmitDisabled(false);

                if (type === 'success') {
                    loadData();
                }
            }, 1400);
        }
    };

    const onDelete = (id) => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the template?',
            onConfirm: () => {
                deleteU(`admin/setting?setting=email_template&id=${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadData();
                        }
                    })
                    .catch((e) => {
                        console.log(e.response)
                    });
            }
        });
    };


    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (!values.email) {
            validComplete = false;
            formErrors.email = 'Поле не должно быть пустым';
        } else if (!values.email.match(/\S+@\S+\.\S+/)) {
            validComplete = false;
            formErrors.email = 'Некорректно заполненные данные';
        }

        if (!values.message) {
            validComplete = false;
            formErrors.message = 'Поле не должно быть пустым';
        } else if (!values.email.match(/\S+@\S+\.\S+/)) {
            validComplete = false;
            formErrors.message = 'Некорректно заполненные данные';
        }

        if (!values.password) {
            validComplete = false;
            formErrors.password = 'Поле не должно быть пустым';
        } else if (values.password.length < 6) {
            validComplete = false;
            formErrors.password = 'Пароль должен быть более 5 символов';
        }



        setErrors(formErrors);
        return validComplete;
    };

    const onSubmit = async () => {
        if (validate()) {
            const payload = {
                email: values.email,
                password_app: values.password,
                emailSend: values.message
            };

            putU(`admin/setting?setting=smtp`, payload)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Почта успешно обновлена');

                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', 'Ошибка');
                })
                .finally(() => {

                });
        }
    };

    const loadData = async () => {
        setDataLoading(true);


        getU('admin/setting?setting=email_template&page=1&limit=9')
            .then((resp) => {
                if (resp.status === 'success') {
                    let templates = resp.data.setting;
                    setEmailTemplates(templates);
                }
            })
            .catch((err) => {
                console.log(err.response)
            })
            .finally(() => {
                setDataLoading(false);
            });

        getU('admin/setting?id=1&setting=smtp')
            .then((response) => {
                if (response.status === 'success') {
                    setValues({
                        email: response.data.setting.value.email,
                        password: response.data.setting.value.password,
                    })
                }
            })
            .catch(() => {

            })
            .finally(() => {

            })


    };

    useEffect(() => {
        loadData();
    }, []);

    if (dataLoading) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Email template list</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Настройки
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to="/app/settings/email/add">
                                <Button color="primary" variant="contained">
                                    Add email template
                                </Button>
                            </Link>
                        </Box>
                        <div className="container">
                            <div className="form">
                                <div className="header">
                                    Обновление почты отправки
                                </div>
                                <div className="inputs">
                                    <Grid container spacing={3} marginTop={0}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                onChange={handleChange}
                                                onFocus={hideValue}
                                                required
                                                type="email"
                                                value={values.email}
                                                variant="outlined"
                                                error={errors.email}
                                                helperText={errors.email}
                                            />
                                            {!errors.email &&
                                            <div className="helperMess">
                                                Ваша почта
                                            </div>
                                            }

                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Пароль"
                                                name="password"
                                                onChange={handleChange}
                                                onFocus={hideValue}
                                                required
                                                type={showPassword ? "text" : "password"}
                                                value={values.password}
                                                variant="outlined"
                                                error={errors.password}
                                                helperText={errors.password}
                                                InputProps={{ // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                onMouseDown={() => setShowPassword(!showPassword)}
                                                            >
                                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            {!errors.password &&
                                            <div className="helperMess">
                                                Пароль приложения
                                            </div>
                                            }
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Почта получения сообщения"
                                                name="message"
                                                onChange={handleChange}
                                                onFocus={hideValue}
                                                required
                                                type="message"
                                                value={values.message}
                                                variant="outlined"
                                                error={errors.message}
                                                helperText={errors.message}
                                            />
                                            {!errors.message &&
                                            <div className="helperMess">
                                                Почта, на которую придет проверочное пиcьмо
                                            </div>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Alert severity={alert.type}
                                           style={{display: alert.isVisible ? 'flex' : 'none', margin: '12px 0'}}>
                                        {alert.txt}
                                    </Alert>
                                    <div>
                                        <Button
                                            className="button"
                                            color="primary"
                                            variant="contained"
                                            disabled={submitDisabled}
                                            onClick={onSubmit}
                                        >
                                            Сохранить
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {
                                emailTemplates.length > 0 ?
                                    <Box sx={{pt: 3}}>
                                        <Card>
                                            <PerfectScrollbar>
                                                <Box sx={{minWidth: 700}}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Id
                                                                </TableCell>
                                                                <TableCell>
                                                                    Ключ
                                                                </TableCell>
                                                                <TableCell>
                                                                    Название
                                                                </TableCell>
                                                                <TableCell/>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {emailTemplates?.map((template) => (
                                                                <TableRow hover key={template.id}>
                                                                    <TableCell>
                                                                        {template.id}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {template.key.key}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {template.subject}
                                                                    </TableCell>
                                                                    <TableCell sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end'
                                                                    }}>
                                                                        <RouterLink
                                                                            to={`/app/settings/email/edit/${template.id}`}>
                                                                            <Button color="primary"
                                                                                    variant="contained">
                                                                                Edit
                                                                            </Button>
                                                                        </RouterLink>
                                                                        <Button sx={{ml: 1}} color="primary"
                                                                                variant="contained"
                                                                                onClick={() => onDelete(template.id)}>
                                                                            Delete
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </PerfectScrollbar>
                                        </Card>
                                    </Box>
                                    :
                                    <Typography color="textPrimary" variant="h2" style={{marginTop: 10}}>
                                        Шаблоны не были загружены
                                    </Typography>
                            }
                        </div>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default EmailSettings;
