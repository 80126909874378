import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {makeStyles} from '@material-ui/styles';
import {Link as RouterLink, useParams, useNavigate, Link} from 'react-router-dom';
import {useGet} from '../../API/request';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../styles/Users/Users.css'
import {BallTriangle} from "react-loader-spinner";

const UserInfo = () => {

    const getU = useGet();
    const {id} = useParams();
    const [user, setUser] = useState({});

    const [isLoaded, setIsLoaded] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoaded(true)

        getU(`admin/user/${id}`)
            .then((resp) => {
                console.log(resp.data)
                if (resp.status === 'success') {
                    setUser(resp.data.user)
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);

    const testData = [
        {
            id: 1,
            line: 1,
        },
        {
            id: 2,
            line: 2,
        },
        {
            id: 3,
            line: 3,
        },
        {
            id: 4,
            line: 4,
        },
        {
            id: 5,
            line: 5,
        },
        {
            id: 6,
            line: 6,
        },

    ]

    const stateItems = [
        {
            id: 1,
            img: "https://media.istockphoto.com/vectors/payment-technology-icon-near-field-communication-concept-fast-payment-vector-id1156465506?b=1&k=20&m=1156465506&s=170667a&w=0&h=AAYaWg2AHpOpUPLjmF7M6t4SFgVFdV0rQlb59fqvT8g=",
            name: "DEL",
            count: 155
        },
        {
            id: 2,
            img: "https://media.istockphoto.com/vectors/payment-technology-icon-near-field-communication-concept-fast-payment-vector-id1156465506?b=1&k=20&m=1156465506&s=170667a&w=0&h=AAYaWg2AHpOpUPLjmF7M6t4SFgVFdV0rQlb59fqvT8g=",
            name: "BOOST",
            count: 53
        },
        {
            id: 3,
            img: "https://media.istockphoto.com/vectors/payment-technology-icon-near-field-communication-concept-fast-payment-vector-id1156465506?b=1&k=20&m=1156465506&s=170667a&w=0&h=AAYaWg2AHpOpUPLjmF7M6t4SFgVFdV0rQlb59fqvT8g=",
            name: "CRT",
            count: 123
        },
        {
            id: 4,
            img: "https://media.istockphoto.com/vectors/payment-technology-icon-near-field-communication-concept-fast-payment-vector-id1156465506?b=1&k=20&m=1156465506&s=170667a&w=0&h=AAYaWg2AHpOpUPLjmF7M6t4SFgVFdV0rQlb59fqvT8g=",
            name: "BRT",
            count: 44
        },
        {
            id: 5,
            img: "https://media.istockphoto.com/vectors/payment-technology-icon-near-field-communication-concept-fast-payment-vector-id1156465506?b=1&k=20&m=1156465506&s=170667a&w=0&h=AAYaWg2AHpOpUPLjmF7M6t4SFgVFdV0rQlb59fqvT8g=",
            name: "ART",
            count: 123
        }
    ]

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>User</title>
            </Helmet>
            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Пользователи
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Пользователи</li>
                    <li>/</li>
                    <li>Информация</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default'}}>

                <Container maxWidth={false} >
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}} >
                        <Box sx={{marginLeft: 2}}>
                            <Link to={`/app/user/${id}/history`}>
                                <Button color="primary" variant="contained">
                                    Истории
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <Card>
                        <CardContent sx={{p: 3}}>
                            <div className="wrapAvatar">
                                <Avatar src={user.avatar} className="avatar"/>
                                <div>
                                    <div className="wrap">
                                        <div className="label">
                                            ID:
                                        </div>
                                        <div className="text">
                                            {user?.id || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Роль:
                                        </div>
                                        <div className="text">
                                            {user?.role?.user_role_russia || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Login:
                                        </div>
                                        <div className="text">
                                            {user?.login || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Имя:
                                        </div>
                                        <div className="text">
                                            {user?.name || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Фамилия:
                                        </div>
                                        <div className="text">
                                            {user?.surname || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Email:
                                        </div>
                                        <div className="text">
                                            {user?.email || '---'}
                                        </div>
                                    </div>
                                </div>
                                <div className="blanceWrappe">
                                    <div className="balanceBlock">
                                        <div className="balanceTextFirst">
                                            {user.wallet.balance.amount} CRYG
                                        </div>
                                        <div className="balanceTextSecond">
                                            {user.wallet.balance.amount * Number(user.wallet.info.value)} RUB
                                        </div>
                                        <div className="balanceTextThird">
                                            {user.wallet.info.value} RUB за 1 CRYG
                                        </div>
                                    </div>
                                    {/*<div className="statingWrap">*/}
                                    {/*    {*/}
                                    {/*        stateItems.map((item) => (*/}
                                    {/*            <div className="stateItem" key={item.id}>*/}
                                    {/*                <img*/}
                                    {/*                    src={item.img}*/}
                                    {/*                    alt=""*/}
                                    {/*                    className="stateItemAva"*/}
                                    {/*                />*/}
                                    {/*                <div className="stateItemName">*/}
                                    {/*                    {*/}
                                    {/*                        item.name*/}
                                    {/*                    }*/}
                                    {/*                </div>*/}
                                    {/*                <div className="stateItemCount">*/}
                                    {/*                    {*/}
                                    {/*                        item.count*/}
                                    {/*                    }*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        ))*/}
                                    {/*    }*/}


                                    {/*</div>*/}
                                </div>

                            </div>
                        </CardContent>
                    </Card>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', py: 1}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 3}}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{minWidth: 300}}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography
                                            sx={{p: 2}}
                                            variant="h6"
                                            id="tableTitle"
                                            component="div"
                                        >
                                            Рефералы
                                        </Typography>
                                        <Link to={`/app/user/edit/${user.id}/referral-history`}>
                                            <Button color="primary"
                                                    variant="contained"
                                                    sx={{
                                                        m: 2,
                                                        width: 120
                                                    }}
                                            >
                                                История
                                            </Button>
                                        </Link>
                                    </Box>

                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Линия
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Просмотреть
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                testData.map((item) => {
                                                    return (
                                                        <TableRow style={{textAlign: 'center',}}>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                Линия {
                                                                item.line
                                                            }
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}} sx={{ml: 2}}>
                                                                <Link
                                                                    to={`/app/user/edit/${user.id}/referral-line/${item.line}`}>
                                                                    <Button color="primary"
                                                                            variant="contained"
                                                                    >
                                                                        Информация.
                                                                    </Button>
                                                                </Link>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

            {/*<Box sx={{backgroundColor: 'background.default', py: 1}}>*/}
            {/*    <Container maxWidth={false}>*/}
            {/*        <Box sx={{pt: 3}}>*/}
            {/*            <Card>*/}
            {/*                <PerfectScrollbar>*/}
            {/*                    <Box sx={{minWidth: 1050}}>*/}
            {/*                        <Typography*/}
            {/*                            sx={{p: 2}}*/}
            {/*                            variant="h6"*/}
            {/*                            id="tableTitle"*/}
            {/*                            component="div"*/}
            {/*                        >*/}
            {/*                            Токены*/}
            {/*                        </Typography>*/}
            {/*                        <Table>*/}
            {/*                            <TableHead>*/}
            {/*                                <TableRow>*/}
            {/*                                    <TableCell>*/}
            {/*                                        Токены 1*/}
            {/*                                    </TableCell>*/}
            {/*                                    <TableCell>*/}
            {/*                                        Токены 2*/}
            {/*                                    </TableCell>*/}
            {/*                                    <TableCell>*/}
            {/*                                        Токены 3*/}
            {/*                                    </TableCell>*/}
            {/*                                    <TableCell>*/}
            {/*                                        Токены 4*/}
            {/*                                    </TableCell>*/}
            {/*                                </TableRow>*/}
            {/*                            </TableHead>*/}
            {/*                            <TableBody>*/}
            {/*                                {*/}
            {/*                                    testData.map((item) => {*/}
            {/*                                        return (*/}
            {/*                                            <TableRow>*/}
            {/*                                                <TableCell>*/}
            {/*                                                    Токены 1*/}
            {/*                                                </TableCell>*/}
            {/*                                                <TableCell>*/}
            {/*                                                    Токены 2*/}
            {/*                                                </TableCell>*/}
            {/*                                                <TableCell>*/}
            {/*                                                    Токены 3*/}
            {/*                                                </TableCell>*/}
            {/*                                                <TableCell>*/}
            {/*                                                    Токены 4*/}
            {/*                                                </TableCell>*/}
            {/*                                            </TableRow>*/}
            {/*                                        )*/}
            {/*                                    })*/}
            {/*                                }*/}
            {/*                            </TableBody>*/}
            {/*                        </Table>*/}
            {/*                    </Box>*/}
            {/*                </PerfectScrollbar>*/}
            {/*            </Card>*/}
            {/*        </Box>*/}
            {/*    </Container>*/}
            {/*</Box>*/}
        </>
    );
};

export default UserInfo;
