import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    Input
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {get, put, useGet, usePut} from '../../API/request';
import {setAva} from '../../redux/slices/dashboard';

const AccountProfile = (props) => {

    const [uploadedAvatar, setUploadedAvatar] = useState('');
    const [userAvatar, setUserAvatar] = useState('');
    const [userName, setUserName] = useState('');

    const name = useSelector(state => state?.auth.user?.name)
    const dispatch = useDispatch();
    const getU = useGet();
    const putU = usePut();

    const uploadNewAvatar = () => {
        if (uploadedAvatar !== '') {

            let data = new FormData();
            data.append('file', uploadedAvatar);

            putU(`users`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        setUserAvatar(resp.data.user.avatar);
                        dispatch(setAva(resp.data.user.avatar))
                    }
                })
                .catch((e) => {

                })
                .finally(() => {

                });
        }
    };

    const avaUploaded = (event) => {
        setUploadedAvatar(event.target.files[0]);
    };


    useEffect(() => {

        getU('users')
            .then((response) => {
                if (response.status === "success") {
                    setUserName(response.data.user.name)
                    setUserAvatar(response.data.user.avatar)
                } else {
                    setUserName('Admin')
                }
            }).catch((e) => {
            setUserName('Admin')
        })

    }, [name]);

    useEffect(() => {
        uploadNewAvatar();
    }, [uploadedAvatar]);

    return (
        <Card {...props}>
            <CardContent>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Avatar
                        src={userAvatar}
                        sx={{
                            height: 100,
                            width: 100,
                            marginBottom: 2
                        }}
                    />
                    <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h3"
                    >
                        {userName || 'Admin'}
                    </Typography>
                </Box>
            </CardContent>
            <Divider/>
            <CardActions>
                <Box style={{width: '100%'}}>
                    <Input accept="xlsx/*" type="file" style={{display: 'none'}} id="userAvatarUpload"
                           onChange={avaUploaded}/>
                    <label htmlFor="userAvatarUpload">
                        <Button variant="text" style={{marginTop: '7px'}} fullWidth component="span">
                            Загрузить аватар
                        </Button>
                    </label>
                </Box>
            </CardActions>
        </Card>
    )
};

export default AccountProfile;
