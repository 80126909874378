import {Helmet} from 'react-helmet';
import {Box, Container, Grid} from '@material-ui/core';
import CustomersStatistics from '../../components/dashboard/CustomersStatistics';
import LatestOrders from '../../components/dashboard/LatestOrders';
import LatestProducts from '../../components/dashboard/LatestProducts';
import Sales from '../../components/dashboard/Sales';
import ProfitPerMonth from '../../components/dashboard/ProfitPerMonth';
import DelStatistics from '../../components/dashboard/DelStatistics';
import TotalProfit from '../../components/dashboard/TotalProfit';
import TrafficByDevice from '../../components/dashboard/TrafficByDevice';
import React, {useEffect, useState} from 'react';
import {useGet} from '../../API/request';
import {BallTriangle} from 'react-loader-spinner';
import CrygStatistics from "../../components/dashboard/CrygStatistics";
import StatisticsItems from "../../components/dashboard/StatisticsItems";

const Dashboard = () => {

    const [loaded, setLoaded] = useState(true);

    const getU = useGet();

    const [data, setData] = useState({
        quantityCRYG: 0,
        quantityDEL: 0,
        quantityUsers: 0,
        quantityReferralRegistration: 0,
        quantityReferralBonusDeposit: 0
    });

    useEffect(() => {
        getU('admin/statistics')
            .then((response) => {
                if (response.status === 'success') {
                    setData({
                        quantityCRYG: response.data.quantityCRYG || 0,
                        quantityDEL: response.data.quantityDEL || 0,
                        quantityUsers: response.data.quantityUsers,
                        quantityReferralRegistration: response.data.quantityReferralRegistration || 0,
                        quantityReferralBonusDeposit: response.data.quantityReferralBonusDeposit || 0
                    });
                    setLoaded(false);
                }
            })
            .catch(() => {
                setLoaded(false);
            });
    }, []);

    if (loaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        )
    }

    return (
        <>

            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <CustomersStatistics quantityUsers={data?.quantityUsers}/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <DelStatistics quantityDEL={data?.quantityDEL}/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <CrygStatistics quantityCRYG={data?.quantityCRYG}/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItems
                                count={data?.quantityReferralRegistration}
                                bcgColor={"rgb(224,255,214)"}
                                name={"CRYG"}
                                desc={"Количество полученных CRYG пользователями за приглашенного реферрала"}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItems
                                count={data?.quantityReferralBonusDeposit}
                                bcgColor={"rgb(227,227,227)"}
                                name={"CRYG"}
                                desc={"Количество полученных CRYG пользователями за % который их рефераллы ввели как депозит в приложение"}
                            />
                        </Grid>

                    </Grid>
                </Container>
            </Box>
        </>
    );
};


export default Dashboard;
