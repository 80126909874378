import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import {useGet, usePut} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";

const UserEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();

    const getU = useGet();
    const putU = usePut();

    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        login: '',
        avatar: '',
        phoneNumber: '',
        password: '',
        confirm: ''
    });
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        email: false,
        login: false,
        avatar: false,
        phoneNumber: false,
        password: false,
        confirm: false
    });

    const [isLoaded, setIsLoaded] = useState(true);

    const [isAvaLoaded, setIsAvaLoaded] = useState(false);
    const [uploadedImgName, setUploadedImgName] = useState('');

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [role, setRole] = useState("1");
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });
    const [dataBeforeChange, setDataBeforeChange] = useState({});

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const avaUploaded = (event) => {
        setNothingChanged(false);

        let pathParts = event.target.value.split('\\');
        setUploadedImgName(pathParts[pathParts.length - 1]);
        setIsAvaLoaded(true);
        setValues({
            ...values,
            avatar: event.target.files[0]
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const roleChanged = (evt) => {
        setNothingChanged(false);
        setRole(evt.target.value);
    };

    const resetPass = () => {
        setValues({
            ...values,
            password: '',
            confirm: ''
        })
    }

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.firstName === '') {
            validComplete = false;
            formErrors.firstName = true;
        }

        if (values.lastName === '') {
            validComplete = false;
            formErrors.lastName = true;
        }

        if (values.login === '') {
            validComplete = false;
            formErrors.login = true;
        }

        if (values.password) {
            console.log("true")

            if (values.password === '') {
                validComplete = false;
                formErrors.password = true;
            }
            if (values.password.length < 6) {
                validComplete = false;
                formErrors.password = true;
            }
            if (values.password !== values.confirm) {
                validComplete = false;
                formErrors.confirm = true;
            }
        }


        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Nothing changed');
            return;
        }

        if (validate()) {
            setSubmitDisabled(true);


            const payload = {
                name: values.firstName,
                surname: values.lastName,
                role_id: role,
            };

            if (values.password) {
                payload.password = values.password;
            }

            if (dataBeforeChange.login !== values.login) {
                payload.login = values.login
            }

            if (values.avatar !== '') {
                payload.file = values.avatar;
            }

            setIsLoaded(true);

            let data = new FormData();
            for (let key in payload) {
                data.append(key, payload[key]);
            }

            putU(`admin/user/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Данные успешно обновленны');
                        resetPass();
                    } else {
                        showAlert('error', 'Ошибка');
                    }

                    setIsLoaded(false);
                })
                .catch((err) => {
                    console.log(err.response)
                    setIsLoaded(false);
                    if (err.response.status === 400) {
                        if (err.response.data.message === 'Переданы неверные данные') {
                            showAlert('error', 'Переданы не верные данные');
                        }
                        if (err.response.data.message === 'You cannot change the data of this user (he has the same rights as you)') {
                            showAlert('error', 'Вы не можете поменять данные другого администратора');
                        } else {
                            showAlert('error', 'Ошибка сервера');
                        }
                    } else {
                        showAlert('error', 'Ошибка сервера');
                    }
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };

    useEffect(() => {
        getU(`admin/user/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    console.log(resp)
                    let userData = resp.data.user;
                    let vals = {
                        email: userData.email,
                        firstName: userData.name || '',
                        lastName: userData.surname || '',
                        phoneNumber: userData.phoneNumber || '',
                        login: userData.login,
                        avatar: '',
                    };

                    let beforeChange = {
                        email: userData.email,
                        login: userData.login
                    };

                    setDataBeforeChange(beforeChange);
                    setValues(vals)
                    setRole(userData.role.role_id)

                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при попытке загрузить пользователя');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit user</title>
            </Helmet>

            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Пользователи
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Пользователи</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>


            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование пользователя"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <Typography color="textPrimary" variant="h4">Аватар</Typography>
                                    <Box>
                                        <Input accept="image/*" type="file" style={{display: 'none'}}
                                               id="userAvatarUpload"
                                               onChange={avaUploaded}/>
                                        <label htmlFor="userAvatarUpload">
                                            <Button variant="contained" style={{marginTop: '7px'}} component="span">
                                                Загрузить
                                            </Button>
                                        </label>
                                        <Typography
                                            style={{
                                                display: isAvaLoaded ? 'inline' : 'none',
                                                marginLeft: '8px',
                                                position: 'relative',
                                                top: '3.5px'
                                            }}
                                        >
                                            {uploadedImgName}
                                        </Typography>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        label="Имя"
                                        margin="normal"
                                        name="firstName"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.firstName}
                                        variant="outlined"
                                        error={errors.firstName}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Фамилия"
                                        margin="normal"
                                        name="lastName"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.lastName}
                                        variant="outlined"
                                        error={errors.lastName}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Login"
                                        margin="normal"
                                        name="login"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.login}
                                        variant="outlined"
                                        error={errors.login}
                                        inputProps={{maxLength: 12}}
                                    />
                                    <FormControl disabled={role === 3} fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="userRole">Роль</InputLabel>
                                        <Select
                                            labelId="userRole"
                                            name="role"
                                            value={role}
                                            label="Role"
                                            onChange={roleChanged}
                                        >
                                            <MenuItem value="1">Пользователь</MenuItem>
                                            <MenuItem value="2">Администратор</MenuItem>
                                            <MenuItem disabled value="3">Супер Администатор</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        label="Пароль"
                                        margin="normal"
                                        name="password"
                                        onChange={handleChange}
                                        type="password"
                                        value={values.password}
                                        variant="outlined"
                                        error={errors.password}
                                        helperText={errors.password && 'Password shouldn\'t be shorter than 6 characters'}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Повторите пароль"
                                        margin="normal"
                                        name="confirm"
                                        onChange={handleChange}
                                        type="password"
                                        value={values.confirm}
                                        variant="outlined"
                                        error={errors.confirm}
                                        helperText={errors.confirm && 'Passwords are different'}
                                    />

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>


                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default UserEdit;
