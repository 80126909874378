import {Navigate} from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import UserAdd from './pages/User/UserAdd';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import DeletedUsers from "./pages/User/DeletedUsers";
import EmailSettings from "./pages/Settings/Email/EmailTemplateList";
import EmailTemplateEdit from "./pages/Settings/Email/EmailTemplateEdit";
import EmailTemplateCreate from "./pages/Settings/Email/EmailTemplateCreate";
import Tokens from "./pages/Tokens/Tokens";
import UserUpBalance from "./pages/User/UserUpBalance";
import ReferralLine from "./pages/User/Referrals/ReferralLine";
import ReferralHistory from "./pages/User/Referrals/ReferralHistory";
import UserHistory from "./pages/User/UserHistory";
import Logs from "./pages/Settings/Logs/Logs";
import Config from "./pages/Settings/Configs/Configs";
import ConfigsEdit from "./pages/Settings/Configs/ConfigsEdit";
import PaymentList from "./pages/Payment/PaymentList";


const routes = [
    {
        path: 'app',
        element: <DashboardLayout/>,
        children: [
            {path: 'account', element: <Account/>},
            {path: 'dashboard', element: <Dashboard/>},

            {path: 'user/:id', element: <UserInfo/>},
            {path: 'users/add', element: <UserAdd/>},
            {path: 'user/edit/:id', element: <UserEdit/>},
            {path: 'users/deleted', element: <DeletedUsers/>},
            {path: 'user/up-balance/:id', element: <UserUpBalance/>},
            {path: 'user/:id/history', element: <UserHistory/>},
            {path: 'users', element: <UserList/>},

            {path: 'user/edit/:id/referral-line/:refId', element: <ReferralLine/>},
            {path: 'user/edit/:id/referral-history', element: <ReferralHistory/>},


            {path: 'payment', element: <PaymentList/>},


            {path: 'settings/email', element: <EmailSettings/>},
            {path: 'settings/email/edit/:id', element: <EmailTemplateEdit/>},
            {path: 'settings/email/add', element: <EmailTemplateCreate/>},
            {path: 'logs', element: <Logs/>},
            {path: 'configs', element: <Config/>},
            {path: 'configs/edit/:name', element: <ConfigsEdit/>},

            {path: 'tokens', element: <Tokens/>},

            {path: '*', element: <Navigate to="/404"/>}
        ]
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {path: 'login', element: <Login/>},
            {path: '404', element: <NotFound/>},
            {path: '/', element: <Navigate to="/app/dashboard"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    }
];

export default routes;
