import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/confirm";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from 'moment';

const PaymentList = () => {

    const getU = useGet();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
    const [queryParams, setQueryParams] = useState({
        decimal: searchParams.get("decimal") || '',
    });
    const [curSearchVal, setCurSearchVal] = useState('All');
    const [upDownSort, setUpDownSort] = useState('desc');

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {}
        if (queryParams.decimal !== '') {
            params.decimal = queryParams.decimal
        }
        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }

    const loadPayments = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        let endpoint = `admin/transaction?filter=${curSearchVal}&sortingName=amount_DEL&sorting=${upDownSort}&page=${page + 1}&limit=${limit}`;
        if (queryParams.decimal !== '') {
            endpoint += `&find=${searchParams.get("decimal")}`;
        }

        getU(endpoint)
            .then((resp) => {
                console.log(resp.data)
                if (resp.status === 'success') {
                    setPayments(resp.data.transactions);
                    setCount(resp.data.allCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                console.log(err.response)
                setPayments([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (queryParams.decimal === '') {
            searchParams.delete("decimal")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        loadPayments();
    }, [page, limit, searchParams, upDownSort,curSearchVal]);



    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Payment</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Платежи
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{pt: 1, px: 2}} fullWidth>
                                                <Box fullWidth>
                                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                                        <InputLabel id="searchType">
                                                            Выберите тип сортировки
                                                        </InputLabel>
                                                        <Select
                                                            labelId="searchType"
                                                            name="searchValue"
                                                            value={curSearchVal}
                                                            label="Выберите тип сортировки"
                                                            onChange={(evt) => setCurSearchVal(evt.target.value)}
                                                        >
                                                            <MenuItem value="All">
                                                                Все платежи
                                                            </MenuItem>
                                                            <MenuItem value="Success">
                                                                Успешные платежи
                                                            </MenuItem>
                                                            <MenuItem value="Fail">
                                                                Провальные платежи
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                                        <InputLabel id="upDownType">
                                                            Выберите тип сортировки
                                                        </InputLabel>
                                                        <Select
                                                            labelId="upDownType"
                                                            name="upDownValue"
                                                            value={upDownSort}
                                                            label="Выберите тип сортировки"
                                                            onChange={(evt) => setUpDownSort(evt.target.value)}
                                                        >
                                                            <MenuItem value="desc">
                                                                По возрастанию суммы
                                                            </MenuItem>
                                                            <MenuItem value="asc">
                                                                По убыванию суммы
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    mb: 1
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Кошелек decimal"
                                                        margin="normal"
                                                        name="decimal"
                                                        onChange={handleChangeQueryParams}
                                                        type="text"
                                                        value={queryParams.decimal}
                                                        variant="outlined"
                                                        style={{marginRight: 10}}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={handleFilterQueryParams}
                                                        sx={{mt: 2, mb: 1}}
                                                    >
                                                        Применить
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box sx={{minWidth: 1400}}>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: 80}}>
                                                                id
                                                            </TableCell>
                                                            <TableCell sx={{width: 80}}>
                                                                transaction_id
                                                            </TableCell>
                                                            <TableCell>
                                                                От кого
                                                            </TableCell>
                                                            <TableCell>
                                                                Кому
                                                            </TableCell>
                                                            <TableCell>
                                                                Количество CRYG
                                                            </TableCell>
                                                            <TableCell>
                                                                Количество DEL
                                                            </TableCell>
                                                            <TableCell>
                                                                Статус
                                                            </TableCell>
                                                            <TableCell>
                                                                Дата
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {payments?.map((item) => (
                                                            <TableRow hover key={item.id}>
                                                                <TableCell sx={{width: 80}}>
                                                                    {item?.id || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item?.transaction_id || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item?.from_id || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item?.to_id || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item?.amount_CRYG || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item?.amount_DEL || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item?.status || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {moment(item.date_create).format('HH:mm, DD.MM.YYYY')}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Кол-во строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default PaymentList;
